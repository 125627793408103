import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponseEvents } from '../interfaces/IEvents';

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  public currentDate: Date = new Date();

  constructor(private http: HttpClient) {}

  public getEvents(): Observable<IResponseEvents> {
    return this.http.get<IResponseEvents>(`@cms/events?sort[0]=dateStart:desc&populate=deep,8`);
  }

  public getActiveEvents(): Observable<IResponseEvents> {
    return this.http.get<IResponseEvents>(
      `@cms/events?filters[dateStart][$lte]=${this.currentDate.toISOString()}&filters[dateEnd][$gte]=${this.currentDate.toISOString()}&populate=deep,8`,
    );
  }
}
