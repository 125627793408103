import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { IData, IEventsBlockTypes, IResponseEvents } from '../interfaces/IEvents';
import { EventsApiService } from './events.api.service';

@Injectable({ providedIn: 'root' })
export class EventsService implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  private events$: BehaviorSubject<IEventsBlockTypes[]> = new BehaviorSubject([]);
  private event$: BehaviorSubject<IEventsBlockTypes> = new BehaviorSubject(null);
  public heroBlock$: BehaviorSubject<IEventsBlockTypes> = new BehaviorSubject(null);
  public eventsSplice$: BehaviorSubject<IEventsBlockTypes[]> = new BehaviorSubject([]);
  public requestCompleted$: Subject<boolean> = new Subject<boolean>();

  private eventApiService: EventsApiService = inject(EventsApiService);

  public get alreadyDownload(): number {
    return this.events$.getValue().length;
  }

  public getEvent(id?: string): Observable<IEventsBlockTypes> {
    const event: IEventsBlockTypes = this.events.find((item: IEventsBlockTypes): boolean => {
      return item.path === id;
    });

    return of(event).pipe(tap((value: IEventsBlockTypes) => this.event$.next(value)));
  }

  private formattedEvents: (response: IResponseEvents) => IEventsBlockTypes[] = (
    response: IResponseEvents,
  ): IEventsBlockTypes[] => {
    return response.data.map((x: IData) => x.attributes);
  };

  public getActiveEvents(): Observable<IEventsBlockTypes[]> {
    return this.eventApiService.getActiveEvents().pipe(
      map(this.formattedEvents),
      tap((responseFormat: IEventsBlockTypes[]): void => {
        this.getEventLastDate(responseFormat);
      }),
      takeUntil(this.destroy$),
    );
  }

  public getEventsData(): Observable<IEventsBlockTypes[]> {
    return this.eventApiService.getEvents().pipe(
      map(this.formattedEvents),
      tap((responseFormat: IEventsBlockTypes[]): void => {
        this.eventsSplice$.next(this.getSpliceEvents(responseFormat));
        this.events$.next(responseFormat);
      }),
      takeUntil(this.destroy$),
    );
  }

  private getSpliceEvents(events: IEventsBlockTypes[]): IEventsBlockTypes[] {
    return events.filter((event: IEventsBlockTypes) => {
      return event.path !== this.heroBlock$.value?.path;
    });
  }

  public get events(): IEventsBlockTypes[] {
    return [...this.events$.value];
  }

  public get event(): IEventsBlockTypes {
    return this.event$.value;
  }

  public getEventLastDate(heroBlocks: IEventsBlockTypes[]): void {
    if (heroBlocks.length > 0) {
      heroBlocks = heroBlocks.sort((a: IEventsBlockTypes, b: IEventsBlockTypes) => {
        return Number(b.priority) - Number(a.priority);
      });
    }
    this.heroBlock$.next(heroBlocks[0]);
    this.requestCompleted$.next(true);
  }

  public get isLiveEvent(): boolean {
    return Boolean(this.heroBlock$.value);
  }

  ngOnDestroy(): void {
    this.event$.complete();
    this.events$.complete();
    this.heroBlock$.complete();
    this.eventsSplice$.complete();
    this.requestCompleted$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
